import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem, Box, Card, CardContent } from '@mui/material';
//import Grid from '@mui/material/Grid2';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
  //table
import { styled } from '@mui/material/styles';
import createAuthInterceptor from '../utils/authInterceptor';

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));


const UfficiSearchAndUpdate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar(); // Ottieni enqueueSnackbar

  const axiosInstance = createAuthInterceptor(navigate);

  const [denominazione, setDenominazione] = useState('');

  const [campagne, setCampagne] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [stato, setStato] = useState('');

  const [ufficioInfo, setUfficioInfo] = useState(null);
  // Carica le campagne disponibili
  const { REACT_APP_API_ENDPOINT } = process.env;

  const API_URL = `${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni`;


  

  useEffect(() => {
    axiosInstance.get(`${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni`).then((res) => {
      setCampagne(res.data);
    });
  }, []);

  // cerco l'ufficio tramite denominazione

  const handleSearch = async () => {
   

    try {
      const res = await axios.get(`${REACT_APP_API_ENDPOINT}/api/uffici/${denominazione}`);
      console.log(res);
      setUfficioInfo(res.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Caso in cui il record non viene trovato
        enqueueSnackbar('Ufficio non trovato. Controlla la denominazione e riprova.', { variant: 'warning' });
      } else {
        // Caso di errore generico
        enqueueSnackbar('Si è verificato un errore durante la ricerca. Riprova più tardi.', { variant: 'error' });
      }
      console.error('Errore nella ricerca:', error);
    }
  };

  /* const handleUpdate = async () => {
    if (!selectedCampaign || !ufficioInfo) return;
  
    try {
      await axios.post(
        `${REACT_APP_API_ENDPOINT}/api/ufficio-campagna/aggiorna`,
        {
          campaignId: selectedCampaign,
          ufficioId: ufficioInfo._id,
          stato,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      alert('Stato aggiornato con successo');
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dello stato:', error);
      alert('Errore durante l\'aggiornamento dello stato');
    }
  }; */


const handleUpdate = async () => {
  
  if (!selectedCampaign || !ufficioInfo) {
    enqueueSnackbar('Seleziona una campagna e un ufficio per continuare.', { variant: 'warning' });
    return;
  }

  try {
    const response = await axios.post(
      `${REACT_APP_API_ENDPOINT}/api/ufficio-campagna/aggiorna`,
      {
        campaignId: selectedCampaign,
        ufficioId: ufficioInfo._id,
        stato,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.data.alreadyCompleted) {
      enqueueSnackbar('Ufficio già completato. Nessuna modifica necessaria.', { variant: 'info' });
    } else {
      enqueueSnackbar('Stato aggiornato con successo.', { variant: 'success' });
    }
  } catch (error) {
    console.error('Errore durante l\'aggiornamento dello stato:', error);

    // Messaggio di errore per l'utente
    enqueueSnackbar('Errore durante l\'aggiornamento dello stato. Riprova più tardi.', { variant: 'error' });
  }
};


  return (

    <>
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
  <Card variant="outlined" sx={{ maxWidth: 800, padding: 2 }}>
  <CardContent>

  <Box
  component="form"
  sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
  noValidate
 // autoComplete="off"
>
  <TextField label="Denominazione ufficio" variant="filled"  focused  id='crcaUfficio'
  value={denominazione}
  onChange={(e) => setDenominazione(e.target.value.toUpperCase())}/>
  <Button variant="contained" onClick={handleSearch}>Cerca Ufficio Postale
  </Button>
 
    {ufficioInfo && (
      <>
      <Stack spacing={2}>
      <Item> {`${ufficioInfo.regione} - ${ufficioInfo.citta}`}</Item>
      <Item>{`${ufficioInfo.denominazione}`}</Item>
      <Item>{`${ufficioInfo.indirizzo}`}</Item>
    </Stack>

    

      <Stack direction="row" spacing={2} sx={{ flexWrap: 'wrap',  p: 2  }}>
      <FormControl sx={{ m: 1, minWidth: 180 }}>
      <InputLabel id="demo-simple-select-autowidth-label">Campagna</InputLabel>
      <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
          autoWidth
          label="Campagna"
      >
          {campagne.map((campagna) => (
              <MenuItem key={campagna._id} value={campagna._id}>
                  {campagna.denominazione} - {campagna.anno}
              </MenuItem>
          ))}
      </Select>
  </FormControl>
          <FormControl sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Stato</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={stato}
            onChange={(e) => setStato(e.target.value)}
            autoWidth
            label="Campagna"
          >
          <MenuItem value="in_progress">In Progress</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>
      <Button variant="contained" onClick={handleUpdate}>
      Aggiorna Stato
      </Button>
    </Stack>
    
        </>
     
    )}

    </Box>
  </CardContent>
  </Card>
     </Box>
     </>
  );
};

export default UfficiSearchAndUpdate;
