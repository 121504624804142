import * as React from 'react';

import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import { itIT } from '@mui/x-data-grid/locales';
const paginationModel = { page: 0, pageSize: 15 };



 function TableStatDepositi(props) {

    const [columnVisibilityModel] = React.useState({
        _id: false,
        id: false,
      });

/*    
{
    "_id": "67800668468140a667b7448a",
    "campaignId": "673f1c6dbf84a4b37da8ac9e",
    "garageId": "66f7bd9cbf7b41110a6cff6c",
    "managed": true,
    "ultimaModifica": "2025-01-09T17:24:56.294Z",
    "complete": true,
    "numero_auto": 9,
    "created": "2025-01-09T17:24:56.294Z",
    "campaignObjId": "673f1c6dbf84a4b37da8ac9e",
    "garageObjId": "66f7bd9cbf7b41110a6cff6c",
    "campaignInfo": {
        "_id": "673f1c6dbf84a4b37da8ac9e",
        "denominazione": "CORPORATE",
        "descrizione": "Campagna Depositi",
        "active": true,
        "created": "2024-11-21T20:01:59.397Z",
        "anno": 2024
    },
    "garageInfo": {
        "_id": "66f7bd9cbf7b41110a6cff6c",
        "regione": "LOMBARDIA",
        "localita": "MILANO",
        "indirizzo": "Via Benozzo Gozzoli, 51, 20152 Milano MI, Italia",
        "CAP": 20152,
        "provincia": "MI",
        "recapito": "CD MILANO BAGGIO",
        "lat": 45.4555516,
        "lng": 9.0909392
    }
}
*/

    const columns = [
        { field: 'id', headerName: 'RowID', width: 70, hidden: true },
        { field: '_id', headerName: 'ID', width: 170, hidden: true },
        { field: 'regione', headerName: 'Regione', width: 130 },
        { field: 'localita', headerName: 'Localita', width: 130 },
        { field: 'recapito', headerName: 'Recapito', width: 250 },
        { field: 'numero_auto', headerName: 'n. Auto', width: 100 },
        
        { field: 'complete', headerName: 'Stato', width: 140,
            cellClassName: (params) => {
                return "super-app";
              },
              renderCell: (params) => {
               // const color = getColor(params.value);
          
                return (
                  <Box
                    sx={{
                      backgroundColor: 'green',
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {params.value}
                  </Box>
                );
              },

         }
    ];

    const rows = [];

      for (let index = 0; index < props.val.length; index++) {
        rows.push({ 
          id: index, 
          _id: props.val[index]._id, 
          regione: props.val[index].garageInfo.regione, 
          localita: props.val[index].garageInfo.localita,
          recapito: props.val[index].garageInfo.recapito, 
          numero_auto: props.val[index].numero_auto,
          complete: props.val[index].complete === true ? "100% completato" : "No"});
        
      }

  return (
    <Paper sx={{  width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        columnVisibilityModel={columnVisibilityModel}
        density="compact"
       // checkboxSelection
       slots={{ toolbar: GridToolbar }}
        sx={{ border: 0 }}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </Paper>
  );
}
export default TableStatDepositi;