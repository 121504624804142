import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import RegistrationNewForm from './RegistrationNewForm';

const RegistrationModal = ({ open, onClose, onUserCreated }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <RegistrationNewForm onClose={onClose} onUserCreated={onUserCreated} />
      </DialogContent>
    </Dialog>
  );
  
  export default RegistrationModal;
