import React, { useState, useEffect } from 'react';
import ImageUploadDepositi from './ImageUploadDepositi'; // Importa il componente ImageUpload
import { getCampaigns, getRegions, getCities, getGarages, getUfficioDepositoId } from './apiDepositi'; // Assicurati che il percorso sia corretto
import { Select, MenuItem, FormControl, InputLabel, Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';

const CampaignDepositiSelection = (props) => {
const [campaigns, setCampaigns] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [garages, setGarages] = useState([]);
  const [selectedNote, setSelectedNote] = useState('');
  //const [selectedAnno, setSelectedAnno] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedGarage, setSelectedGarage] = useState('');
  const [selectedDepositoId, setSelectedDepositoId] = useState('')

 // Carica le campagne all'avvio
 useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const data = await getCampaigns();
        setCampaigns(data);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    fetchCampaigns();
  }, []);

  // Carica le regioni all'avvio
  useEffect(() => {
    const fetchRegions = async () => {
        try {
            const response = await getRegions();
            console.log(response)
            setRegions(response);
        } catch (error) {
            console.error('Error fetching regions:', error);
        }
           
    };

    fetchRegions();
  }, []);

  // Popola le città in base alla regione selezionata
  useEffect(() => {
    const fetchCities = async () => {
      if (selectedRegion) {
        const response = await getCities(selectedRegion);
        setCities(response);
      }
    };

    fetchCities();
  }, [selectedRegion]);

  // Popola i garage in base alla città selezionata
  useEffect(() => {
    const fetchGarages = async () => {
      if (selectedCity) {
        const response = await getGarages(selectedCity);
        setGarages(response);
      }
    };

    fetchGarages();
  }, [selectedCity]);

    // recupera l' _id dell'uficio per perjointare i mq
useEffect(() => {
  const fetchDeposidoId = async () => {
      if (selectedGarage) {
        const response = await getUfficioDepositoId(selectedGarage);
        setSelectedDepositoId(response);
      }
    };

    fetchDeposidoId();
}, [selectedGarage])

    // Funzione per resettare gli stati dopo un caricamento riuscito
    const handleUploadSuccess = () => {
      setSelectedCampaign('');
      setSelectedRegion('');
      setSelectedCity('');
      setSelectedGarage('');
      setSelectedNote('');
      setSelectedDepositoId('');
    };

  return (
    <Container>
    
      <h2>Carica immagini per i Depositi</h2>
      
      <Grid container spacing={1} style={{marginBottom: '25px'}}>

    {/*  <Grid size={4}>
      <Grid   xs={12} sm={5} md={4}>
      <FormControl  fullWidth >
        <InputLabel id="select-anno-label">Seleziona Anno</InputLabel>
        <Select
        style={{padding:'4px 4px'}}
        value={selectedAnno}
        onChange={(e) => setSelectedAnno(e.target.value)}
        labelId="anno-select-label"
        label={"Seleziona Anno"}
        >
        <MenuItem key={'2024'} value={'2024'}>
              {'2024'}
            </MenuItem>
        </Select>
      </FormControl>
      </Grid>
    </Grid>
 */}
        <Grid size={4}>
          <Grid   xs={12} sm={5} md={4}>
          <FormControl  fullWidth >
            <InputLabel id="campagna">Seleziona Campagna</InputLabel>
            <Select
            labelId="campagna-label"
              label={"Seleziona Campagna"}
            style={{padding:'4px 4px'}}
            value={selectedCampaign}
            onChange={(e) => setSelectedCampaign(e.target.value)}
            >
              {campaigns?.map((campaign) => (
                <MenuItem key={campaign._id} value={campaign._id}>
                  {campaign.denominazione} - {campaign.anno}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        </Grid>

        <Grid size={4}>
        <Grid  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="regione">Seleziona Regione</InputLabel>
              <Select
              labelId="regione-label"
              label={"Seleziona Regione"}
              style={{padding:'4px 4px'}}
                value={selectedRegion}
                onChange={(e) => setSelectedRegion(e.target.value)}
              >
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid size={4}>
        <Grid  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="citta">Seleziona Città</InputLabel>
              <Select
              labelId="citta-label"
              label={"Seleziona Città"}
              style={{padding:'4px 4px'}}
                value={selectedCity === -1 ? '' : selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                disabled={!selectedRegion} // Disabilita il selettore città finché la regione non è selezionata
              >
                {cities.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid size={8}>
        <Grid   xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="deposito">Deposito</InputLabel>
              <Select
                style={{padding:'4px 4px'}}
                labelId="deposito-label"
                label={"Seleziona deposito"}

                id="deposito-simple-select"
                value={selectedGarage}
                onChange={(e) => setSelectedGarage(e.target.value)}
                disabled={!selectedCity} // Disabilita il selettore garage finché la città non è selezionata
              >
                {garages?.map((garage) => (
                  <MenuItem key={garage} value={garage}>
                    {garage}
                  </MenuItem>
                ))}
              </Select>
            </FormControl >
          </Grid>
        </Grid>
        <Grid size={12}>
        <Grid   xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <TextField 
              id="outlined-basic-note" 
              label="Note (facoltativo)" 
              variant="outlined" 
              value={selectedNote === -1 ? '' : selectedNote}
              onChange={(e) => setSelectedNote(e.target.value)}
              disabled={!selectedCity}
              />
            </FormControl >
          </Grid>
        </Grid>

        </Grid>
  {/* 
      <select onChange={(e) => setSelectedCampaign(e.target.value)}>
        <option value="">Seleziona Campagna</option>
        {campaigns?.map((campaign) => (
          <option key={campaign._id} value={campaign._id}>{campaign.denominazione}</option>
        ))}
      </select>

      <select onChange={(e) => setSelectedRegion(e.target.value)}>
        <option value="">Seleziona Regione</option>
        { regions?.map(region => (
        <option key={region} value={region}>{region}</option>
      )) }
      </select>

      <select onChange={(e) => setSelectedCity(e.target.value)}>
        <option value="">Seleziona Città</option>
        {cities?.map((city) => (
          <option key={city} value={city}>{city}</option>
        ))}
      </select>

      <select onChange={(e) => setSelectedGarage(e.target.value)}>
        <option value="">Seleziona Garage</option>
        {garages?.map((garage) => (
          <option key={garage} value={garage}>{garage}</option>
        ))}
      </select>
*/}
      {/* Mostra l'uploader delle immagini dopo che tutte le selezioni sono state fatte */}
      {selectedCampaign && selectedRegion && selectedCity && selectedGarage  ? (
        <ImageUploadDepositi 
        //  anno={selectedAnno}
          campaign={selectedCampaign} 
          region={selectedRegion} 
          city={selectedCity} 
          garage={selectedGarage}
          depositoId={selectedDepositoId}
          note={selectedNote}
          onSuccess={handleUploadSuccess} // Passa la funzione di successo
        />
      ) : (
        <p>Completa tutte le selezioni per caricare le immagini.</p>
      )}
    </Container>
  );
};

export default CampaignDepositiSelection;
