
import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Button, FormControl, InputLabel } from '@mui/material';
import { Typography, Card, Container, CardHeader, CardContent, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { getUfficioDepositoId } from './ApiManagedDepositi'; // Assicurati che il percorso sia corretto
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import createAuthInterceptor from '../../../utils/authInterceptor';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { enqueueSnackbar } from 'notistack';

/*
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
*/
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const { REACT_APP_API_ENDPOINT } = process.env;

//import { Navigate, useNavigate } from 'react-router-dom';


const ManagedPanelDepositi = () => {
  const navigate = useNavigate();
  const axiosInstance = createAuthInterceptor(navigate);
  const [campaigns, setCampaigns] = useState([]);
  const [regioni, setRegioni] = useState([]);
  const [citta, setCitta] = useState([]);
  const [garage, setGarage] = useState([]);
  const [selectedAnno, setSelectedAnno] = useState('');
  const [selectedCampaigns, setSelectedCampaigns] = useState('');
  const [selectedRegione, setSelectedRegione] = useState('');
  const [selectedCitta, setSelectedCitta] = useState('');
  const [selectedGarage, setSelectedGarage] = useState('');

//  const [deposito, setDeposito] = useState([]);
  const [depositoId, setDepositoId] = useState({})

  //const navigate = useNavigate();
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [noInsertion, setNoInsertion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [conflict, setConflict] = useState('');

 const [index, setIndex] = useState(-1);
const currentImage = images[index];
const nextIndex = (index + 1) % images.length;
const nextImage = images[nextIndex] || currentImage;
const prevIndex = (index + images.length - 1) % images.length;
const prevImage = images[prevIndex] || currentImage;

const handleClick = (index, item) => setIndex(index);
const handleClose = () => setIndex(-1);
const handleMovePrev = () => setIndex(prevIndex);
const handleMoveNext = () => setIndex(nextIndex);

// Per recuperare le campagne disponibili dal backend
  useEffect(() => {
    const fetchCampaignMenu = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns`);
        setCampaigns(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle regioni:', error);
      }
    };

    fetchCampaignMenu();
  }, []);

  // Per recuperare le regioni disponibili dal backend
  useEffect(() => {
    const fetchRegioni = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/regioni`);
        setRegioni(response.data);
      } catch (error) {
        console.error('Errore nel recupero delle regioni:', error);
      }
    };

    fetchRegioni();
  }, []);

  // Quando una regione è selezionata, recupera le città corrispondenti
  useEffect(() => {
    if (selectedRegione) {
      const fetchCitta = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/citta?regione=${selectedRegione}`);
          setCitta(response.data);
        } catch (error) {
          console.error('Errore nel recupero delle città:', error);
        }
      };

      fetchCitta();
    }
  }, [selectedRegione]);

  // Quando una città è selezionata, recupera i garage corrispondenti
  useEffect(() => {
    if (selectedCitta) {
      const fetchGarage = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/indirizzo?citta=${selectedCitta}`);
          setGarage(response.data);
        } catch (error) {
          console.error('Errore nel recupero dei depositi:', error);
        }
      };

      fetchGarage();
    }
  }, [selectedCitta]);

    // recupera l' _id del deposito per perjointare i mq
useEffect(() => {
  const fetchUfficioId = async () => {
      if (selectedGarage) {
        const response = await getUfficioDepositoId(selectedGarage);
        setDepositoId(response);
      }
    };

    fetchUfficioId();
}, [selectedGarage])
 
const handleFilter = () => {
  // Invio di una richiesta con i parametri di filtro
  axios
    .get(`${REACT_APP_API_ENDPOINT}/api/managed/campaign-images`, {
      params: {
        campaignId: selectedCampaigns,
        regione: selectedRegione,
        citta: selectedCitta,
        garage: selectedGarage,
        anno:selectedAnno,
      },
    })
    .then((response) => {
      console.log('Immagini filtrate:', response.data);
      if(response.data.length > 0) {
      setNoRecordFound(false);
    } else {
      setNoRecordFound(true);
    }
      // Gestione delle immagini filtrate
      setImages(response.data);
    })
    .catch((error) => {
      console.error('Errore nel recupero delle immagini:', error);
    });
};
/*
  const handleFilter = () => {
    // Invio di una richiesta con i parametri di filtro
    axios
      .post(`${REACT_APP_API_ENDPOINT}/api/auto-campagna/managed/recupera`, {     
          campaignId: selectedCampaigns,
          garageId: depositoId._id,
      })
      .then((response) => {
        console.log('Immagini filtrate:', response.data);

        if(response.data.length > 0) {
        setNoRecordFound(false);
        setNoRecordFound(false);
      } else {
        setNoRecordFound(true);
      }
        // Gestione delle immagini filtrate
        setDeposito(response.data);
      })
      .catch((error) => {
        console.error('Errore nel recupero delle immagini:', error);
        setNoRecordFound(true);
      });
  };
*/
  const handleComplete = () => {
    setConflict('');
    axios
      .post(`${REACT_APP_API_ENDPOINT}/api/managed/depositi/completed`, {     
          campaignId: selectedCampaigns,
          garageId: depositoId._id,
      })
      .then((response) => {
        console.log('completed:', response.data);
        if(response.data) {
            setNoInsertion(true);
            
      } else {
        setNoInsertion(false);
      }
        // Gestione delle immagini filtrate
        console.log(response.data)
      })
      .catch((error) => {
        console.error('Errore nell\'inserimento:', error.response.data);
        setConflict(error.response.data.message);
        setNoInsertion(false);
      });

  }


  const handleDelete = async (imageId, index) => {
    try {
      console.log(imageId)
      await axiosInstance.get(`/api/images/delete-image/${imageId}`);
      setImages(images.filter((image) => image._id !== imageId));
      enqueueSnackbar('Immagine eliminata con successo', { variant: 'success' });

    } catch (error) {
      console.error('Errore durante l\'eliminazione dell\'immagine:', error);
    }
  };

  const handleReset = () => {
   
    setSelectedRegione('');
    setSelectedCitta('');
    setSelectedGarage('');
    console.log("resetto")
    setLoading(false);
}


const handleValidateImages = async () => {
  const imageIds = images.map((image) => image._id);

  try {
    const response = await axiosInstance.put('/api/images/validate', { ids: imageIds });
    console.log(response.data.message);
    alert(response.data.message);
    handleFilter();
    // Aggiorna lo stato o notifica il successo
  } catch (error) {
    console.error("Error validating images:", error);
    alert(error);
    // Gestisci l'errore, ad esempio mostrando una notifica
  }
};

  return (
    <>
    
    <Container >
    <div style={{ marginBottom: '25px' }}>
      <h2>Managed Panel Depositi</h2>
      </div>
      <Stack direction="row" spacing={2} sx={{mb: 2}}>
      {loading ? 
        <CircularProgress color="inherit" />
        : <IconButton aria-label="delete" onClick={()=> { setLoading(true); handleReset();} }>
        <AutorenewIcon />
      </IconButton> }
      
      <Box sx={{ '& > button': { m: 1 } }}>
      {loading ? 
        <LoadingButton
          size="small"
          
          loading={loading}
          variant="outlined"
          disabled
        >
          Disabled
        </LoadingButton>
        : null }
        </Box>
    </Stack>
      <Grid container spacing={2} style={{marginBottom: '25px'}}>

   {/*    <Grid size={4}>
      <Grid item={true}  xs={12} sm={5} md={4}>
      <FormControl  fullWidth >
        <InputLabel id="select-anno-label">Seleziona Anno</InputLabel>
        <Select
        style={{padding:'4px 4px'}}
        value={selectedAnno}
        onChange={(e) => setSelectedAnno(e.target.value)}
        labelId="anno-select-label"
        label={"Seleziona Anno"}
        >
        <MenuItem key={'2024'} value={'2024'}>
              {'2024'}
            </MenuItem>
        </Select>
      </FormControl>
      </Grid>
    </Grid> */}

        <Grid size={6}>
          <Grid item={true}  xs={12} sm={5} md={4}>
          <FormControl  fullWidth >
            <InputLabel id="campagna-label">Seleziona Campagna</InputLabel>
            <Select
            style={{padding:'4px 4px'}}
            value={selectedCampaigns}
            onChange={(e) => setSelectedCampaigns(e.target.value)}
            labelId="campagna-select-label"
            label={"Seleziona campagna"}
            >
              {campaigns.map((campain) => (
                <MenuItem key={campain._id} value={campain._id}>
                  {campain.denominazione} - {campain.anno}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        </Grid>

        <Grid size={6}>
        <Grid item={true}  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="regione-label">Seleziona Regione</InputLabel>
              <Select
              style={{padding:'4px 4px'}}
                value={selectedRegione}
                onChange={(e) => setSelectedRegione(e.target.value)}
                labelId="regione-select-label"
                label={"Seleziona Regione"}
              >
                {regioni.map((regione) => (
                  <MenuItem key={regione} value={regione}>
                    {regione}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        <Grid size={6}>
        <Grid item={true}  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="citta-label">Seleziona Città</InputLabel>
              <Select
              style={{padding:'4px 4px'}}
                value={selectedCitta}
                onChange={(e) => setSelectedCitta(e.target.value)}
                disabled={!selectedRegione} // Disabilita il selettore città finché la regione non è selezionata
                labelId="citta-select-label"
                label={"Seleziona città"}
                >
                {citta.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid size={6}>
        <Grid item={true}  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="garage-label">Deposito</InputLabel>
              <Select
               style={{padding:'4px 4px'}}
               labelId="garage-select-label"
               label={"Garage"}
                value={selectedGarage}
                onChange={(e) => setSelectedGarage(e.target.value)}
                disabled={!selectedCitta} // Disabilita il selettore garage finché la città non è selezionata
              >
                {garage.map((g) => (
                  <MenuItem key={g} value={g}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
            </FormControl >
          </Grid>
        </Grid>
        <Grid size={4}>
      <Button
              variant="contained"
              
              onClick={handleFilter}
            >
              Filtra depositi
            </Button>
        </Grid>
        <Grid size={4}>
      <Button
              variant="contained"
              color="success"
              onClick={handleComplete}
            >
              Imposta come completato
            </Button>
        </Grid>

        <Grid size={4}>
        <Button
                variant="contained"
                color="success"
                onClick={handleValidateImages}
              >
                Valida filtrate
              </Button>
          </Grid>
      </Grid>

      {noRecordFound ?  
        <h2>
        Nessuna immagine trovata
        </h2> 
        : null
      }

      { 
        noInsertion ?
        <h2>
        Inserito con successo
        </h2> 
        :
        null
      }

      {
       <h1>{conflict}</h1> 
      }


      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {images.map((image, index) => (
        <Grid key={image._id} size={{ xs: 2, sm: 4, md: 4 }}>
          
          <Card key={image._id} sx={{ maxWidth: 345 }}>
          <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                <LocationOnIcon />
                </Avatar>
              }
              action={
                  <>
          <IconButton aria-label="settings" sx={{ color: 'red' }} onClick={() => handleDelete(image._id, index)}>
            <DeleteIcon />
          </IconButton>
          {image.status === "validated" ? 
            <IconButton aria-label="settings" sx={{ color: 'red' }} disabled>
            <CheckIcon sx={{color: 'green'}} />
          </IconButton>
          : null
          }
          </>
        }
              
              title={image.depositoPt.recapito}
            //  subheader={`MTb:${image.depositoPt.MTb} - MTh:${image.depositoPt.MTh}`}
            />
            <CardMedia
              component="img"
              height="140"
              image={`${REACT_APP_API_ENDPOINT}/api/${image.path}`}
              alt="Immagine"
              onClick={()=>handleClick(index)}
            />
            <CardContent>
             {image.note &&
                <Typography variant="body2" color="text.secondary" >
                Note: {image.note}
                </Typography>
              }
              <Typography variant="body2" color="text.secondary">
                 Utente: {image.username}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Data: {new Date(image.uploadDate).toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
         
        </Grid>
      ))}
      </Grid>
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={`${REACT_APP_API_ENDPOINT}/api/${currentImage.path}`}
          imageTitle={`${currentImage.depositoPt.recapito} - ${currentImage.username} - ${new Date(currentImage.uploadDate).toLocaleDateString()}`}
          mainSrcThumbnail={currentImage.path}
          nextSrc={`${REACT_APP_API_ENDPOINT}/api/${nextImage.path}`}
          nextSrcThumbnail={`${REACT_APP_API_ENDPOINT}/${nextImage.path}`}
          prevSrc={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
          prevSrcThumbnail={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      </Container>



      </>
  );
};

export default ManagedPanelDepositi;
