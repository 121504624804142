//`api/images/all-images:date?${new Date().toJSON()}

import React, { useEffect, useState } from 'react';
import createAuthInterceptor from '../utils/authInterceptor';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
import { Container, Box, Typography, Card, CardMedia, CardContent, IconButton } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const { REACT_APP_API_ENDPOINT } = process.env;



function GetAllImages() {
  const navigate = useNavigate();
  const axiosInstance = createAuthInterceptor(navigate);
  const [images, setImages] = useState([]);

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const [expanded, setExpanded] = React.useState(false);

const handleExpandClick = () => {
  setExpanded(!expanded);
};

  useEffect(() => {
    const fetchImages = async () => {
      try {

        await axiosInstance.get(`/api/manage/images/all-images`).then((resp) => {
          setImages(resp.data);
       })      
        
      } catch (error) {

        console.error('Errore nel recupero delle immagini:', error);
        // La notifica viene gestita dall'intercettore, quindi non è necessario duplicarla qui
      
      }
    };

    fetchImages();
  }, []);

  const handleDelete = async (imageId, index) => {
    try {
      console.log(imageId)
      await axiosInstance.get(`/api/images/delete-image/${imageId}`);
      setImages(images.filter((image) => image._id !== imageId));
      enqueueSnackbar('Immagine eliminata con successo', { variant: 'success' });

    } catch (error) {
      console.error('Errore durante l\'eliminazione dell\'immagine:', error);
    }
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Le Tue Immagini
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {images.map((image, index) => (
            <Card key={image._id} sx={{ maxWidth: 345, position: 'relative' }}>
            <CardHeader
        avatar={
          <PlaceIcon  aria-label="recipe">
            L
          </PlaceIcon>
        }
        action={
          <IconButton aria-label="settings" sx={{ color: 'red' }} onClick={() => handleDelete(image._id, index)}>
            <DeleteIcon />
          </IconButton>
        }
        title={image?.ufficioPt === '' ? image.depositoPt.recapito : image.ufficioPt.denominazione}
        
      />
              <CardMedia
                component="img"
                height="140"
                image={`${REACT_APP_API_ENDPOINT}/api/${image.path}`}
                alt="Immagine"
                onClick={()=>handleClick(index)}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {image.username}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {image.address}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {new Date(image.uploadDate).toLocaleString()}
                </Typography>
                {image.note &&
                    <Typography sx={{ marginBottom: 2 }}>
                  {image.note}
                  </Typography>
                  }
              </CardContent>

            </Card>
          ))}

          {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={`${REACT_APP_API_ENDPOINT}/api/${currentImage.path}`}
          imageTitle={currentImage.address}
          mainSrcThumbnail={currentImage.path}
          nextSrc={`${REACT_APP_API_ENDPOINT}/api/${nextImage.path}`}
          nextSrcThumbnail={`${REACT_APP_API_ENDPOINT}/api/${nextImage.path}`}
          prevSrc={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
          prevSrcThumbnail={`${REACT_APP_API_ENDPOINT}/api/${prevImage.path}`}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}

        </Box>
      </Box>


    </Container>
  );
}

export default GetAllImages;
