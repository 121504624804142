import * as React from 'react';

import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import { itIT } from '@mui/x-data-grid/locales';
const paginationModel = { page: 0, pageSize: 15 };



 function TableStatUffici(props) {

    const [columnVisibilityModel] = React.useState({
        _id: false,
        id: false,
      });

/*     const getColor = (value) => {
        const hue = value;
      
        return ["hsl(", hue, ",100%,50%)"].join("");
      }; */

    const columns = [
        { field: 'id', headerName: 'RowID', width: 70, hidden: true },
        { field: '_id', headerName: 'ID', width: 170, hidden: true },
        { field: 'MQ', headerName: 'MQ', width: 70 },
        { field: 'denominazione', headerName: 'Denominazione', width: 130 },
        { field: 'numero_Uffici', headerName: 'N. Uffici', width: 100 },
        { field: 'completed', headerName: 'Stato', width: 140,
            cellClassName: (params) => {
                return "super-app";
              },
              renderCell: (params) => {
               // const color = getColor(params.value);
          
                return (
                  <Box
                    sx={{
                      backgroundColor: 'green',
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {params.value}
                  </Box>
                );
              },

         }
    ];

    const rows = [];

      for (let index = 0; index < props.val.length; index++) {
        rows.push({id: index, _id: props.val[index]._id, MQ: props.val[index].MQ, denominazione: props.val[index].denominazione, numero_Uffici: props.val[index].numero_uffici, completed: props.val[index].complete === true ? "100% completato" : "No"});
        
      }





    /*
{
    "_id": "679d0067a41c80c4a5bb86ec",
    "campaignId": "679b9343a41c80c4a5bb86b3",
    "ufficioId": "670a3deba45953b8f7a4575f",
    "managed": true,
    "ultimaModifica": "2025-01-31T16:55:03.592Z",
    "complete": true,
    "numero_uffici": 1,
    "MQ": 1.61,
    "denominazione": "ROMA 173",
    "regione": "LAZIO",
    "citta": "ROMA",
    "created": "2025-01-31T16:55:03.592Z",
    "anno": "",
    "ufficioObjId": "670a3deba45953b8f7a4575f",
    "ufficioDetails": {
        "_id": "670a3deba45953b8f7a4575f",
        "regione": "LAZIO",
        "citta": "ROMA",
        "denominazione": "ROMA 173",
        "indirizzo": "Piazza Dei Martiri Di Belfiore, 9, 00195 Roma RM, Italia",
        "materiale": "PVC ADESIVO ONE-WAY TRASLUCENT",
        "MTb": 1.27,
        "MTh": 1.27,
        "MQ": 1.61,
        "lat": 41.9152254,
        "lng": 12.4666944
    },
    "campaignObjId": "679b9343a41c80c4a5bb86b3",
    "campaignInfo": [
        {
            "_id": "679b9343a41c80c4a5bb86b3",
            "anno": 2025,
            "denominazione": "SDECORO CORPORATE",
            "descrizione": "DISALLESTIMENTO CORPORATE",
            "active": true,
            "created": "2025-01-30T14:57:07.434Z"
        }
    ]
}
    */


  return (
    <Paper sx={{  width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        columnVisibilityModel={columnVisibilityModel}
        density="compact"
       // checkboxSelection
       slots={{ toolbar: GridToolbar }}
        sx={{ border: 0 }}
        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      />
    </Paper>
  );
}
export default TableStatUffici;